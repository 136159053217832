import AOS from 'aos';
import 'aos/dist/aos.css';

/* Nav */
let navLink = document.querySelectorAll('.nav__link');

navLink.forEach((link) => {
    link.addEventListener('click', () => {
        document.querySelector('.nav-burger').click();
    });
});

/* Animations */
AOS.init();

if ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    let portfolioImages = document.querySelectorAll('.portfolio__image');

    portfolioImages.forEach((item) => {
        item.style.webkitTransform = 'translateZ(0)';
    });
}
